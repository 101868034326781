/* base */

.breadcrumb {
  background-color: transparent;
}

.inmodal .modal-body-txikia {
  padding: 5px;
}

.inmodal .modal-header-txikia {
  padding: 5px;
  text-align: center;
}

.inmodal .modal-title-txikia {
  font-size: 16px;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  border-top: none; //1px solid $border-color;
  line-height: 1.2;
  padding: 6px;
  vertical-align: middle;
}

/* _navigation */

.nav > li > a {
  color: $nav-text-color;
  font-weight: 400;
  padding: 6px 20px 6px 25px;
}

.nav-header {
  padding: 35px;
  background-color: $nav-bg;
  background-image: $nav-profile-pattern;
}

.nav-second-level li a {
  padding-left: 40px;
}

.nav-third-level li a {
  padding-left: 60px;
}

.navbar-static-top {
  //background-color: darken($nav-bg, 0%);
  background-color: #2F4050;
  background-image: url("patterns/header-profile.png");
  color: $nav-text-color;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.nav.navbar-right > li > a {
  color: $navy;
}

.dropdown-menu > li > a {
  line-height: 10px;
}

.navbar-top-links .dropdown-menu li a div {
  line-height: normal;
}

/* elements */

.list-group-item-heading {
  margin-top: 0px;
}

.list-group-item-text {
  margin: 0px;
}

.list-group-item {
  padding: 2px;
}

.elements-list .list-group-item {
  padding: 2px;
}

.form-group {
  margin-bottom: 2px;
}

.form-control, .single-line {
  padding: 1px 1px;
  height: 27px;
  font-size: 12px;
}

/* datatables */

.buttons-collection > span {
  font-size: 12px !important;
}

.buttons-collection{
  background-color: transparent !important;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 50px;
  height: 27px;
  //line-height: 21px;
}

div.dataTables_wrapper .form-control {
  font-size: 12px;
  height: 28px;
  padding: 5px 5px;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: $navy;
  border-color: $navy;
  color: whitesmoke;
}

@media (max-width: 768px) {

  .dataTables_filter {
    display: inline;
  }

  .dataTables_length {
    display: none;
  }

  .dt-buttons{
    display: none;
  }
}