/* label color */

.label-bezero-handia {
  background-color: $green;
  color: whitesmoke;
}

.label-garapenean {
  background-color: $red;
  color: whitesmoke;
}

.label-proposamena {
  background-color: $blue;
  color: whitesmoke;
}

.label-definizioan {
  background-color: $pink;
  color: whitesmoke;
}

.label-planifikatzeko {
  background-color: $light-green;
  color: whitesmoke;
}

.label-bezero-zain {
  background-color: $purple;
  color: whitesmoke;
}

.label-galdetu {
  background-color: $yellow;
  color: whitesmoke;
}

.label-bonoa {
  background-color: $lazur;
  color: whitesmoke;
}

.label-mantenua {
  background-color: $orange;
  color: whitesmoke;
}

/* estiloak */

.ko_kolorea {
  color: $red;
  font-weight: bold;
}

.ko_fondoa {
  background-color: $red;
  font-weight: bold;
}

.txuria {
  color: whitesmoke;
}

.kolorea {
  color: $navy;
}

.fondo_kolorea {
  color: whitesmoke;
  background-color: $navy;
  padding: 5px;
  border-radius: 5px;
}

.marratuGorriz {
  color: $red;
  text-decoration: line-through;
  font-size: 0.9em;
}

/* menuak */

.marra_sep-right {
  border-right: 1px solid #999c9e;
}

/* taulak */

.navbar-header > small {
  font-size: 13px;
  display: inline-block;
  padding-left: 4px;
  font-weight: 400;
}

.bajan {
  color: darkgrey;
  //text-decoration: line-through;
  //font-size: 0.9em;
}

/* fitxak */

.ondorenTestua {
  color: white;
  background-color: $navy;
  /*margin-left: 27%;*/
  /*margin-right: 12%;*/
  padding-right: 15px;
}

.ondorenTestuaMarra {
  color: $navy;
  padding-right: 15px;
  border-bottom: 2px solid $navy;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px !important;
}

/* azpitaulak */

.azpitaulaIzenburua {
  color: $navy;
  padding: 5px;
  border-bottom: 2px solid $navy;
  margin-left: -15px;
}

.ibox-azpitaula {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox-title-azpitaula {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: $ibox-title-bg;
  //border-color: $border-color;
  border-image: none;
  //border-style: solid solid none;
  //border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 0px 7px 0px;
  min-height: 48px;
}

.ibox-content-azpitaula {
  background-color: $ibox-content-bg;
  color: inherit;
  //padding: 15px 20px 20px 20px;
  //padding: 5px;
  //border-color: $border-color;
  //border-image: none;
  //border-style: solid solid none;
  //border-width: 1px 0;
  margin-left: -30px;
  margin-right: -30px;
}

/* eremuak */

.ezkutatu {
  padding: 5px !important;
  margin-bottom: 0px !important;
}

input:required {
  background-image: url('patterns/required.gif');
  background-position: right top;
  background-repeat: no-repeat;
}

select:required {
  background-image: url('patterns/required.gif');
  background-position: 95% top;
  background-repeat: no-repeat;
  /*background-color: yellow;*/
}

.select-find {
  display: block;
  padding: 2px;
  margin: 0px;
  font-size: 1em;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.fa-check {
  color: $navy;
}

a {
  color: $navy;
}

.kontuZb {
  font-weight: 400;
}

/* botoiak */

.botoiak-eremuak {
  padding: 0px;
  margin: 0px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  padding-top: 0px;
}

.btn-editbotoia {
  color: $navy !important;
}

.btn-group > a > span {
  color: #444;
}

.buttons-collection > span {
  font-size: 12px !important;
}

.botoiak-taulak {
  font-size: 100%;
}

.btn-login {
  background-color: $navy !important;
}

.btn-editbotoia {
  color: $navy !important;
}

.btn-berriabotoia {
  color: $navy !important;
  /*border: 1px solid $navy !important;*/
  /*border-radius: 4px;*/
  /*padding: 2px;*/
  margin-top: -10px;
}

.btn-gorde {
  background-color: $navy !important;
  border-color: $navy !important;
}

.btn-active {
  font-size: 1.2em;
}

.btn-berria-gehitu,
.btn-eskaera-herriaBerria, .btn-eskaera-postakodeaBerria {
  background-color: $navy;
  border-color: $navy;
}

.btn-berria-txikia, .btn-ikusi-berria {
  background-color: $navy;
  border-color: $navy;
}

.btn-restorebotoia, .btn-forceDeletebotoia {
  color: $red;
}

.ondorenBotoia {
  color: $red;
  position: absolute;
  display: inline;
  float: right;
  font-size: 1.5em;
  margin: -30px 0 0 50px;
}